import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import {
  NgbDatepickerModule,
  NgbTimepickerModule,
} from "@ng-bootstrap/ng-bootstrap";

import { PagetitleComponent } from "./pagetitle/pagetitle.component";
import { RouterModule } from "@angular/router";
import { UiSwitchModule } from "ngx-ui-switch";
import { CutStringsPipe } from "src/app/core/pipes/cut-strings.pipe";
import { FiltersComponent } from "./filters/filters.component";
import { TranslateModule } from "@ngx-translate/core";
import { NgSelectModule } from "@ng-select/ng-select";
@NgModule({
  declarations: [PagetitleComponent, CutStringsPipe, FiltersComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgbDatepickerModule,
    NgSelectModule,
    NgbTimepickerModule,
    TranslateModule,
    UiSwitchModule,
  ],
  exports: [
    PagetitleComponent,
    CutStringsPipe,
    UiSwitchModule,
    FiltersComponent,
  ],
})
export class UIModule {}
