import {
  ICityData,
  IdistrictData,
  IregionData,
} from "../models/regions.models";
import { Injectable } from "@angular/core";
import { HttpClientService } from "./http.service";

@Injectable({
  providedIn: "root",
})
export class RegionsService {
  cities = [];
  distrects = [];
  regions = [];
  constructor(private http: HttpClientService) {}

  getAllCities(ICityData: ICityData) {
    return this.http.get("cities", ICityData);
  }
  createCities(body) {
    return this.http.post(body, "cities");
  }
  updateCity(cityId: number, body) {
    return this.http.post(body, `cities/${cityId}`);
  }
  deleteCity(cityId: number) {
    return this.http.delete({}, `cities/${cityId}`);
  }
  getSingleCity(cityId: number) {
    return this.http.get(`cities/${cityId}`);
  }

  getAllDistricts(IdistrictData: IdistrictData) {
    return this.http.get("districts", IdistrictData);
  }
  createDistrict(body) {
    return this.http.postFormData(body, "districts");
  }
  getSingleDistrict(districtId: number) {
    return this.http.get(`districts/${districtId}`);
  }
  updateDistrict(districtId: number, body: any) {
    return this.http.postFormData(body, `districts/${districtId}`);
  }
  deleteDistrict(districtId: number) {
    return this.http.delete({}, `districts/${districtId}`);
  }

  getAllRegions(options: IregionData) {
    return this.http.get("regions", options);
  }
  createRegion(body) {
    return this.http.postFormData(body, "regions");
  }
  getSingleRegion(id: number) {
    return this.http.get(`regions/${id}`);
  }
  updateRegion(id: number, body: any) {
    return this.http.postFormData(body, `regions/${id}`);
  }
  deleteRegion(id: number) {
    return this.http.delete({}, `regions/${id}`);
  }
}
