<div id="preloader" *ngIf="loading">
  <!-- <div id="status" >
        <div class="spinner-chase">
            <div class="chase-dot"></div>
            <div class="chase-dot"></div>
            <div class="chase-dot"></div>
            <div class="chase-dot"></div>
            <div class="chase-dot"></div>
            <div class="chase-dot"></div>
        </div>
    </div> -->
  <span class="loader"></span>
</div>
