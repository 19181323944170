import { Component, OnInit } from "@angular/core";
import { take } from "rxjs/operators";
import { SettingsService } from "src/app/core/services/settings.service";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
})

/**
 * Footer component
 */
export class FooterComponent implements OnInit {
  // set the currenr year
  year: number = new Date().getFullYear();
  buildVerision;
  constructor(private settingsService: SettingsService) {}

  ngOnInit() {
    this.getBuildVerison();
  }

  getBuildVerison() {
    this.settingsService
      .getBuildVerison()
      .pipe(take(1))
      .subscribe((res) => {
        this.buildVerision = res;
      });
  }
}
