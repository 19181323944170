import { Injectable } from "@angular/core";
import { HttpClientService } from "./http.service";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class SettingsService {
  constructor(
    private http: HttpClientService,
    private httpClient: HttpClient
  ) {}

  getAllSettings() {
    return this.http.get("settings", {});
  }

  updateSettings(body) {
    return this.http.post(body, "settings/update-multi");
  }

  getBuildVerison() {
    return this.httpClient.get("assets/files/version.json", {
      responseType: "text",
    });
  }
}
