<footer class="footer">
  <div class="d-flex justify-content-between">
    <p class="mb-0">
      {{ "Last Commit Hash : " + buildVerision }}
    </p>

    <p class="mb-0">
      © {{ year }} Dashboard. Crafted with
      <i class="mdi mdi-heart text-danger"></i> by Digital Creativity
    </p>
  </div>
</footer>
