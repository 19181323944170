import { DOCUMENT } from "@angular/common";
import { Component, Inject, OnInit } from "@angular/core";
import { EventService } from "../../core/services/event.service";

import { LAYOUT_WIDTH, SIDEBAR_TYPE, TOPBAR } from "../layouts.model";

@Component({
  selector: "app-rightsidebar",
  templateUrl: "./rightsidebar.component.html",
  styleUrls: ["./rightsidebar.component.scss"],
})

/**
 * Rightsidebar component
 */
export class RightsidebarComponent implements OnInit {
  isVisible: string;
  attribute: string;

  width: string;
  sidebartype: string;
  topbar: string;
  basecolor = "light";

  constructor(
    private eventService: EventService,
    @Inject(DOCUMENT) private document: any
  ) {}

  ngOnInit() {
    this.width = LAYOUT_WIDTH;
    this.sidebartype = SIDEBAR_TYPE;
    this.topbar = TOPBAR;

    this.themeHandler();
  }

  themeHandler() {
    // handle theme layout
    this.attribute = localStorage.getItem("theme_layout");
    const vertical = document.getElementById("is-layout");
    if (this.attribute == "horizontal" && vertical != null) {
      vertical.removeAttribute("checked");
    } else {
      vertical.setAttribute("checked", "true");
    }
    this.changeLayout(this.attribute);

    // handle theme color
    const currentTheme = localStorage.getItem("theme_color");
    if (currentTheme != "light") {
      this.loadStyle("client-dark.css");
    } else {
      this.loadStyle("client-light.css");
    }

    // handle theme width
    const themewidth = localStorage.getItem("theme_width");
    if (themewidth) {
      this.changeWidth(themewidth);
    }

    // handle sidebar style
    const sidebarStyle = localStorage.getItem("sidebar_style");
    if (sidebarStyle) {
      this.changeSidebartype(sidebarStyle);
    }

    // handle topbar style
    const topbarStyle = localStorage.getItem("topbar_style");
    if (topbarStyle) {
      this.changeTopbar(topbarStyle);
    }
  }

  /**
   * Hide the sidebar
   */
  public hide() {
    document.body.classList.remove("right-bar-enabled");
  }

  /**
   * Change Topbar
   */
  changeTopbar(topbar: string) {
    this.topbar = topbar;
    this.eventService.broadcast("changeTopbar", topbar);
    localStorage.setItem("topbar_style", topbar);
    // document.body.removeAttribute("data-sidebar");
  }

  /**
   * Change the layout onclick
   * @param layout Change the layout
   */
  changeLayout(layout) {
    if (layout?.target?.checked == false || layout == "horizontal") {
      this.attribute = "horizontal";
      this.eventService.broadcast("changeLayout", "horizontal");
      localStorage.setItem("theme_layout", "horizontal");
      document.body.setAttribute("data-layout", "horizontal");
    } else {
      this.attribute = "vertical";
      this.eventService.broadcast("changeLayout", "vertical");
      localStorage.setItem("theme_layout", "vertical");
      document.body.setAttribute("data-layout", "vertical");
    }
  }

  changeWidth(width: string) {
    this.width = width;
    this.eventService.broadcast("changeWidth", width);
    localStorage.setItem("theme_width", width);
  }

  changeSidebartype(sidebar: string) {
    this.sidebartype = sidebar;
    this.eventService.broadcast("changeSidebartype", sidebar);
    localStorage.setItem("sidebar_style", sidebar);
    // document.body.removeAttribute("data-topbar");
  }

  //dark-light mode
  loadStyle(styleName: string) {
    const head = this.document.getElementsByTagName("head")[0];

    let themeLink = this.document.getElementById(
      "client-theme"
    ) as HTMLLinkElement;
    if (themeLink) {
      themeLink.href = styleName;
    } else {
      const style = this.document.createElement("link");
      style.id = "client-theme";
      style.rel = "stylesheet";
      style.href = `${styleName}`;

      head.appendChild(style);
    }

    if (styleName == "client-light.css") {
      localStorage.setItem("theme_color", "light");
      this.basecolor = "light";
      document.body.setAttribute("data-layout-mode", "light");
    } else {
      localStorage.setItem("theme_color", "dark");
      this.basecolor = "dark";
      document.body.setAttribute("data-layout-mode", "dark");
    }
  }
}
