import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";

import { AuthenticationService } from "../../../core/services/auth.service";
import { OwlOptions } from "ngx-owl-carousel-o";
import { ActivatedRoute, Router } from "@angular/router";
import Swal from "sweetalert2";
import { FormManage } from "src/app/core/helpers/formVaild";
import { LanguageService } from "src/app/core/services/language.service";
import { take } from "rxjs/operators";

@Component({
  selector: "app-login2",
  templateUrl: "./login2.component.html",
  styleUrls: ["./login2.component.scss"],
})
/**
 * Login-2 component
 */
export class Login2Component extends FormManage implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private langService: LanguageService
  ) {
    super();
  }

  loginForm: FormGroup;
  submitted = false;
  error = "";
  returnUrl: string;
  show: boolean = false;

  // set the currenr year
  year: number = new Date().getFullYear();

  ngOnInit(): void {
    document.body.classList.add("auth-body-bg");

    this.initForm();
    this.returnUrl = this.route.snapshot.queryParams["returnUrl"] || "/";
    this.languageHandler();
  }

  initForm() {
    this.loginForm = new FormGroup({
      field: new FormControl("", [Validators.required, Validators.email]),
      password: new FormControl("", [
        Validators.required,
        Validators.minLength(6),
      ]),
    });
    this.setForm(this.loginForm);
  }

  languageHandler() {
    const lang = localStorage.getItem("lang") || "ar";
    this.langService.setLanguage(lang);
    if (lang === "en") {
      document.getElementsByTagName("html")[0].setAttribute("dir", "ltr");
      document.getElementsByTagName("html")[0].setAttribute("lang", "en");
      document.body.classList.add("en");
      document.body.classList.remove("ar");
    } else {
      document.getElementsByTagName("html")[0].setAttribute("dir", "rtl");
      document.getElementsByTagName("html")[0].setAttribute("lang", "ar");
      document.body.classList.add("ar");
      document.body.classList.remove("en");
    }
  }

  carouselOption: OwlOptions = {
    items: 1,
    loop: false,
    margin: 0,
    nav: false,
    dots: true,
    responsive: {
      680: {
        items: 1,
      },
    },
  };

  // convenience getter for easy access to form fields
  get f() {
    return this.loginForm.controls;
  }

  /**
   * Form submit
   */

  password() {
    this.show = !this.show;
  }

  /**
   * Form submit
   */
  onSubmit() {
    this.submitted = true;
    if (this.isFormValid) {
      this.authenticationService
        .Login(this.loginForm.value)
        .pipe(take(1))
        .subscribe({
          next: (res) => {
            this.authenticationService.User = res.data;
            document.body.classList.remove("auth-body-bg");

            if ("returnUrl" in this.route.snapshot.queryParams) {
              const returnURL = decodeURIComponent(
                this.route.snapshot.queryParams.returnUrl
              );
              const parsedURL = this.parseURL(returnURL);
              this.router.navigate([parsedURL.mainRoute], {
                queryParams: parsedURL.queryParams,
              });
            } else {
              this.router.navigate(["/dashboard"]);
            }
          },
          error: (err) => (this.submitted = false),
        });
    }
  }

  parseURL(url) {
    const urlParts = url.split("?");
    const mainRoute = urlParts[0];

    if (urlParts.length > 1) {
      const queryParams = urlParts[1].split("&").reduce((params, param) => {
        const [key, value] = param.split("=");
        params[key] = value;
        return params;
      }, {});

      return { mainRoute, queryParams };
    }

    return { mainRoute, queryParams: {} };
  }
}
